<template>
  <base-card dark>
    <v-img
      :src="require('@/assets/articles/home_portrait.jpg')"
      class="grey lighten-2"
      height="400"
      width="100%"
      gradient="rgba(0, 0, 0, .1), rgba(0, 0, 0, .1 )"
    >
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          cols="12"
          md="7"
          offset-md="5"
        >
          <h1 class="display-3 font-weight-light">
            Safe travel and accessible
          </h1>

          <div class="subheading  pl-2 mb-4">
            Aeropuerto-Hotel / Hotel-Aeropuerto
          </div>

          <!-- <v-btn
            color="primary"
            depressed
          >
            Subscribe
          </v-btn> -->
        </v-col>
      </v-row>
    </v-img>
  </base-card>
</template>

<script>
  export default {
    name: 'HomeBanner',
  }
</script>
